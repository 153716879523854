<template>
  <section>
    <div class="content-header">
      <h2>Circles of control, influence and concern</h2>
    </div>
    <div class="content-wrapper">
      <p>Not all crises, conflicts or disruptive behaviours are preventable. There are many moving pieces in learning environments that may be beyond your control.</p>
      <p>The circles of control, influence and concern remind us that there are areas of our lives that we have control over, like what we say, our thoughts and actions, where we live and work, our attitudes and the friends we choose.</p>
      <p>Then there are areas that we may have influence over, like the people in our lives (family, neighbours, co-workers and our community).</p>
      <p>Finally, there are areas that we can have concern over but that we don’t influence or control, like the weather, natural disasters, illness, other people’s actions, world events and politics.</p>
      <img
        src="@/assets/img/_content/community-discuss-04.svg"
        class="img-fluid img-thumb"
        alt="Three concentric circles. The outer circle is labelled “concern”, the next one in is labelled “influence”, and at the centre, “control”"
        srcset=""
      >
      <p class="text-center text-dark-grey pt-2">Circles of control, influence and concern</p>
      <p class="text-center text-dark-grey">Source: Adapted from Stephen Covey’s Circle of Influence and Control</p>
      <p>Think about a conflict that has happened in one of your classes and what caused it. What aspects of your course were within your circle of control, influence and concern? </p>
      <p><strong>Examples</strong></p>
      <ul>
        <li>You likely have <strong>control</strong> over things like assigned readings, assignment structure, response to student comments and questions, introduction of materials, lessons and resources.</li>
        <li>You likely have <strong>influence</strong> over things like setting expectations for discussion and debate, respectful dialogue and student knowledge of resources.</li>
        <li>Areas of <strong>concern</strong>, over which you have no control or influence, might include things like transit running late, snow days and changes to academic policy.</li>
      </ul>

    </div>
  </section>
</template>

<script>
// import Accordion from '@/components/Accordion.vue'
// import SidebarMenu from '@/components/SidebarMenu.vue'
// import {
//   Hooper,
//   Slide,
//   Navigation as HooperNavigation,
//   Pagination as HooperPagination
// } from 'hooper'
// import 'hooper/dist/hooper.css'

export default {
  name: 'content',
  components: {
    // SidebarMenu,
    // Accordion,
    // Hooper,
    // Slide,
    // HooperNavigation,
    // HooperPagination
  },
  data () {
    return {
      publicPath: process.env.BASE_URL
    }
  },

  mounted () {
  }
}
</script>

<style>
</style>
